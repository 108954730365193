import { lazy } from "react";

const HomePage = lazy(() => import('../pages/home'));
const TypeListPage = lazy(() => import('../pages/type-list'));
const GoodsDetailPage = lazy(() => import('../pages/goods-detail'));
const UserCenterPage = lazy(() => import('../pages/user-center'));
const SearchPage = lazy(() => import('../pages/search'));
const PaySuccessPage = lazy(() => import('../pages/result/pay-success'));

const routers = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/page/:page',
    component: HomePage,
  },
  {
    path: '/game',
    component: TypeListPage,
    children: [
      {
        path: 'page/:page',
        component: TypeListPage,
      }
    ]
  },
  {
    path: '/game/:type',
    component: TypeListPage,
    children: [
      {
        path: 'page/:page',
        component: TypeListPage,
      }
    ]
  },
  {
    path: '/course',
    component: TypeListPage,
    children: [
      {
        path: 'page/:page',
        component: TypeListPage,
      }
    ]
  },
  {
    path: '/course/:type',
    component: TypeListPage,
    children: [
      {
        path: 'page/:page',
        component: TypeListPage,
      }
    ]
  },
  {
    path: '/user',
    component: UserCenterPage,
  },
  {
    path: '/user/:id',
    component: UserCenterPage,
  },
  {
    path: '/:id.html',
    component: GoodsDetailPage,
  },
  {
    path: '/search',
    component: SearchPage,
  },
  {
    path: '/pay-success',
    component: PaySuccessPage,
  },
]

export default routers;