import moment from "moment";
import menuList from "../config/menu-config";
import { debug } from "util";

export default class Tools {
  static dateDiff(timestamp: number) {
    let minute = 1000 * 60;
    let hour = minute * 60;
    let day = hour * 24;
    let halfamonth = day * 15;
    let month = day * 30;
    let now = new Date().getTime();
    let diffValue = now - timestamp;

    // 如果本地时间反而小于变量时间
    if (diffValue < 0) {
      return '不久前';
    }

    // 计算差异时间的量级
    let monthC: number = diffValue / month;
    let weekC: number = diffValue / (7 * day);
    let dayC: number = diffValue / day;
    let hourC: number = diffValue / hour;
    let minC: number = diffValue / minute;

    // 数值补0方法
    let zero = function (value: number) {
      if (value < 10) {
        return '0' + value;
      }
      return value;
    };

    // 使用
    if (monthC > 12) {
      // 超过1年，直接显示年月日
      return (function () {
        let date = new Date(timestamp);
        return date.getFullYear() + '年' + zero(date.getMonth() + 1) + '月' + zero(date.getDate()) + '日';
      })();
    } else if (monthC >= 1) {
      return parseInt(monthC.toString()) + "月前";
    } else if (weekC >= 1) {
      return parseInt(weekC.toString()) + "周前";
    } else if (dayC >= 1) {
      return parseInt(dayC.toString()) + "天前";
    } else if (hourC >= 1) {
      return parseInt(hourC.toString()) + "小时前";
    } else if (minC >= 1) {
      return parseInt(minC.toString()) + "分钟前";
    }
    return '刚刚';
  }
  /**
   * 将时间戳或ISO格式的日期字符串格式化为指定格式。
   *
   * @param input - 要格式化的时间戳（单位为毫秒）或ISO格式的日期字符串。
   * @param format - 格式字符串。
   * @returns 格式化后的日期字符串。
   *
   * 格式字符串中的标记：
   * - yyyy: 完整的年份。
   * - MM: 两位数的月份（01-12）。
   * - dd: 两位数的日期（01-31）。
   * - HH: 两位数的小时（24小时制）（00-23）。
   * - mm: 两位数的分钟（00-59）。
   * - ss: 两位数的秒数（00-59）。
   */
  static formatDate(input: number | string | undefined, format: string): string {
    if (!input) return '-';
    const date = new Date(input);

    const map: { [key: string]: string | number } = {
      "yyyy": date.getFullYear(),
      "MM": (date.getMonth() + 1).toString().padStart(2, '0'),
      "dd": date.getDate().toString().padStart(2, '0'),
      "HH": date.getHours().toString().padStart(2, '0'),
      "mm": date.getMinutes().toString().padStart(2, '0'),
      "ss": date.getSeconds().toString().padStart(2, '0'),
    };
  
    // 使用正则表达式匹配格式字符串中的年、月、日等部分，并将其替换为实际的日期值
    return format.replace(/yyyy|MM|dd|HH|mm|ss/g, matched => map[matched].toString());
  }
  
  static isToday(timestamp: number): boolean {
    // 将时间戳转换为Date对象
    const date = new Date(timestamp);
    const today = new Date();

    // 比较年、月、日是否相同
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  }

  /**
   * 获取连续7天日期
   * @returns 返回前连续7天的日期（前四+今天+后二）
   */
  static getSurroundingDates() {
    let dates = [];
    const today = moment(); // 使用moment获取当前日期
    // 获取前4天的日期
    for (let i = 4; i > 0; i--) {
      let date = today.clone().subtract(i, 'days'); // 克隆当前日期并减去天数
      dates.push(date.format('M.D')); // 格式化为月-日格式并添加到数组
    }
    // 添加今天的日期
    dates.push(today.format('M.D'));
    // 获取后2天的日期
    for (let i = 1; i <= 2; i++) {
      let date = today.clone().add(i, 'days'); // 克隆当前日期并加上天数
      dates.push(date.format('M.D')); // 格式化为月-日格式并添加到数组
    }
    return dates;
  }

  /**
   * 
   * @param url 当前路由地址
   * @return 返回需要请求的参数
   */
  static getFetchTypeBuyPathname(url: string, arr = menuList): string | null {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].url == url) {
        return arr[i].type;
      } else if (arr[i].children && arr[i].children?.length) {
        const result = Tools.getFetchTypeBuyPathname(url, arr[i].children);
        if (result) return result;
      }
    }
    return null
  }
}