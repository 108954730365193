import { createContext } from "react";
import { UserType } from "../types";

export const userReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'updateUser':
      return {
        ...state,
        ...action.payload,
      };
    case 'removeUser':
      if (!Object.keys(state).length) return state;
      return {};
    default:
      return state;
  }
}

interface UserContextType {
  state: UserType;
  dispatch: React.Dispatch<any>; // 根据你的实际action类型调整
}

export const UserContext = createContext<UserContextType>({
  state: {
    userId: 0,
    email: '',
    nickName: ''
  },
  dispatch: () => {}
});