import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import { DownOutlined, SearchOutlined, CloseOutlined, UserOutlined, LoadingOutlined, ShoppingCartOutlined, KeyOutlined, AccountBookOutlined, LoginOutlined, CheckOutlined, MehOutlined } from '@ant-design/icons';
import { Input, Avatar, Popover, Button, message, Modal } from 'antd';
import Login from '../Login';
import { UserContext } from '../../utils/reducer';
import { http } from '../../utils/fetch';
import { UserType, SignRecordType } from '../../types';
import Tools from '../../utils/tools';
import eventBus from '../../utils/event';
import menuList from '../../config/menu-config';
import './index.scss';


const LOGINTOKEN: string = process.env.REACT_APP_LOGIN_TOKEN!;
const USERINFO: string = process.env.REACT_APP_USER_INFO!;
const scoreList = [2, 4, 6, 8, 10, 12, 14];
export default () => {
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [isSign, setIsSign] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { state, dispatch } = useContext(UserContext);
  const [signDateList, setSignDateList] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);


  useEffect(() => {
    eventBus.addListener('showLogin', () => {
      dispatch({type: 'removeUser'});
      setShowLoginModal(true);
    })

    return () => {
      eventBus.removeAllListeners();
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem(LOGINTOKEN)) {
      refreshUserInfo();
    }
  }, [])

  useEffect(() => {
    if (state.userId) {
      getSignRecord();
    }
  }, [state.userId])

  //刷新用户信息
  const refreshUserInfo = async () => {
    try {
      const data: any = await http('/user/auth/getUserInfo');
      localStorage.setItem(USERINFO, JSON.stringify(data));
      dispatch({type: 'updateUser', payload: {...data, costScore: Math.abs(data.costScore)}})
    } catch(e) {}
  }

  //获取近七天签到记录（判断今天是否签到）
  const getSignRecord = async () => {
    try {
      const data = await http<SignRecordType[]>('/auth/sign/record');
      const dateList = Tools.getSurroundingDates();
      const formattedDates = formatDatesFromData(data);
      const today = moment().format('M.D');
      let result = [];
      //定义一个变量，表示连续签到天数为n
      let n = 0;
      //定义一个变量，表示最后一天签到是数组里的第几位
      let index = 0;
      for (let i = 0; i < dateList.length; i++) {
        const signed = formattedDates.includes(dateList[i]);
        result[i] = {
          date: dateList[i],
          signed: signed,
          days: 0
        }
        if (signed && today == dateList[i]) {
          setIsSign(true);
        }
        for (let j = 0; j < data.length; j++) {
          if (dateList[i] == moment(data[j].createTime).format('M.D')) {
            result[i].days = data[j].days;
            n = data[j].days;
            index = i;
            break;
          }
        }
      }
      for (let i = 0; i < result.length; i++) {
        if (i >= 4) {
          result[i].days = result[i-1].days + 1;
        }
      }
      setSignDateList(result);
    } catch(e) {
      console.log('e', e);
    }
  }

  function formatDatesFromData(data: SignRecordType[]) {
    return data.map(item => moment(item.createTime).format('M.D'));
  }

  const openLoginModalHandle = () => {
    setShowLoginModal(true);
  }

  const navigateHandle = (path: string) => {
    if (!path) return;
    navigate(path);
  }

  const searchHandle = (event: any) => {
    if (event.keyCode == 13) {//回车键
      const value = event.target.value;
      if (!value) return;
      setShowSearch(false);
      window.open(`/search?key=${value}`)
    }
  }

  const signHandle = async (index: number) => {
    if (isSign || index != 4) {
      return;
    }
    setLoading(true);
    try {
      const data = await http<UserType>('/auth/sign/in');
      setIsSign(true);
      getSignRecord();
      let dif = parseInt((data.score! - state.score!).toString()) ?? 2;
      message.success(`连续签到成功！赠送${dif}积分`);
      localStorage.setItem(USERINFO, JSON.stringify(data));
      dispatch({type: 'updateUser', payload: data})
    } catch(e) {
    } finally {
      setLoading(false);
    }
  }

  const loginoutHandle = async () => {
    if (confirmLoading) return;
    setConfirmLoading(true);
    try {
      await http('/user/auth/logout');
      localStorage.removeItem(LOGINTOKEN);
      localStorage.removeItem(USERINFO);
      dispatch({type: 'removeUser'});
      navigate('/');
    } catch(e) {
      //...
    } finally {
      setOpen(false);
      setConfirmLoading(false);
    }
  }

  const handleCancel = () => {
    setOpen(false);
  }
  

  return (
    <>
      <div className="layout-header">
        {
          !showSearch ? (
            <>
              <div className='logo' onClick={() => navigateHandle('/')}>
                <img src={require('../../assets/logo.png')} alt="" />
                {/* <h4>SoftGameHub</h4> */}
              </div>
              <div className='line'></div>
              <ul className='nav'>
                <li className='nav-item' onClick={() => navigateHandle('/')}><span>首页</span></li>
                {
                  menuList.map(item => {
                    return (
                      <li className='nav-item' key={item.url}>
                        <a onClick={() => navigateHandle(item.url)}><span>{item.label}</span><DownOutlined style={{fontSize: 8}} /></a>
                        {
                          item.children && item.children.length && (
                            <ul className="sub-menu" key={item.url}>
                              {
                                item.children.map(sub => {
                                  return <li key={sub.url} onClick={() => navigateHandle(sub.url)}>{sub.label}</li>
                                })
                              }
                            </ul>
                          )
                        }
                      </li>
                    )
                  })
                }
                {
                  state.userId ? <li className='nav-item'>
                  <a onClick={() => navigateHandle('/user')}><span>用户中心</span></a>
                </li> : null
                }
              </ul>
              <div className='control'>
                <ul className='sign-history'>
                  {
                    state.userId && signDateList.map((item, index) => {
                      return <li key={index} className={item.signed ? 'active' : ''}>
                        <span className={classNames('score', (index == 4 && !isSign) ? 'tody' : '')} onClick={() => signHandle(index)}>
                          {
                            item.signed ? <CheckOutlined /> : (
                              (loading && index == 4) 
                                ? <LoadingOutlined style={{fontSize: 13}} /> 
                                : (index < 4 
                                    ? <MehOutlined style={{fontSize: 15}} /> 
                                    : `+${scoreList[item.days - 1]}`
                                  )
                            )
                          }
                        </span>
                        <span className='date'>{index == 4 ? '今天' : item.date}</span>
                      </li>
                    })
                  }
                </ul>
                {/* {
                  state.userId ? (
                    isSign ? (
                      <div className="sign-btn active">
                        <CheckOutlined style={{marginRight: 4, fontSize: 13}} />
                        <span>已签到</span>
                      </div>
                    ) : (
                      <div className="sign-btn" onClick={signHandle}>
                        { loading ? <LoadingOutlined style={{marginRight: 4, fontSize: 13}} /> : <GiftOutlined style={{marginRight: 4, fontSize: 13}} /> }
                        <span>签到</span>
                      </div>
                    )
                  ) : null
                } */}
                {
                  !state.userId 
                    ? <span className='login-btn' onClick={openLoginModalHandle}>登录</span>
                    : (
                      <Popover
                        placement="bottomRight"
                        trigger="hover"
                        color="var(--theme-black)"
                        content={(
                          <div>
                            {/* <div><MehOutlined style={{color: 'white'}} /><Button style={{color: 'white'}} type='link' onClick={() => navigateHandle('/user/center')}>会员中心</Button></div> */}
                            <div><UserOutlined style={{color: 'white'}} /><Button style={{color: 'white'}} type='link' onClick={() => navigateHandle('/user/info')}>个人资料</Button></div>
                            <div><KeyOutlined style={{color: 'white'}} /><Button style={{color: 'white'}} type='link' onClick={() => navigateHandle('/user/password')}>修改密码</Button></div>
                            <div><AccountBookOutlined style={{color: 'white'}} /><Button style={{color: 'white'}} type='link' onClick={() => navigateHandle('/user/charge')}>充值中心</Button></div>
                            <div><ShoppingCartOutlined style={{color: 'white'}} /><Button style={{color: 'white'}} type='link' onClick={() => navigateHandle('/user/purchase')}>购买记录</Button></div>
                            <div><LoginOutlined style={{color: 'white'}} /><Button style={{color: 'white'}} type='link' onClick={() => setOpen(true)}>退出登录</Button></div>
                          </div>
                        )}
                      >
                        <div className='user-box'>
                          <span className='avatar'><Avatar size={30} icon={<UserOutlined />} src={state.avatar} /></span>
                          <span className="nickname">{state.nickName}</span>
                        </div>
                      </Popover>
                    )
                }
                <span className='search-btn' onClick={() => setShowSearch(true)}><SearchOutlined style={{fontSize: 14, color: 'var(--theme-orange)'}} /></span>
                {/* <div className="user-dropdown">
                  <div className="avatar"></div>
                  <div className="info">
                    <p className="username">mamamiya</p>
                    <div>
                      <span className="level">钻石用户</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </>
          ) : (
            <div className='search-main'>
              <Input autoFocus placeholder='输入关键字，回车...' onKeyDown={searchHandle} />
              <span className="close-btn" onClick={() => setShowSearch(false)}><CloseOutlined style={{fontSize: 14, color: 'var(--theme-orange)'}}  /></span>
            </div>
          )
        }
      </div>
      {
        showLoginModal ? <Login show={showLoginModal} onCancel={() => setShowLoginModal(false)} /> : null
      }
      <Modal
        title="提示"
        open={open}
        onOk={loginoutHandle}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>确定要退出当前登录账号吗？</p>
      </Modal>
    </>
  )
}