import { message } from "antd";
import axios from "axios";
import * as qiniu from 'qiniu-js'
import eventBus from "./event";

const LOGINTOKEN: string = process.env.REACT_APP_LOGIN_TOKEN!;
const USERINFO: string = process.env.REACT_APP_USER_INFO!;
const qiniuBaseURL = process.env.REACT_APP_QINIU_BASE_URL;

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
export interface FetchConfig {
  baseUrl?: string
  method?: 'POST' | 'GET'
  data?: object
  params?: object
}

axios.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem(LOGINTOKEN);
    if (token) {
      config.headers.token = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export const http = <T>(url: string, config?: FetchConfig): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    axios({
      url: '/api' + url,
      method: config?.method || 'GET',
      data: config?.data,
      params: config?.params,
    }).then((res: any) => {
      if (parseInt(res.data?.code) === 200) {
        resolve(res.data.data as T);
      } else {
        switch (parseInt(res.data?.code)) {
          case 401:
            localStorage.removeItem(LOGINTOKEN);
            localStorage.removeItem(USERINFO);
            eventBus.emit('showLogin');
            break;
          default:
            break;
        }
        message.error(res.data.message);
        reject(res.data);
      }
    }).catch(error => {
      reject(error.data);
    })
  })
}


interface QiniuTokenResultType {
  token: string,
  key: string
}

/**
 * 获取七牛云上传token
 * @param ext 文件后缀
 * @returns 返回Promise：QiniuTokenResultType
 */
const getQiniuUploadToken = (ext: string): Promise<QiniuTokenResultType> => {
  const time = Date.now();
  const _key = `${time}.${ext}`;

  return new Promise((resolve, reject) => {
    axios({
      url: `/api/upload/token?key=${_key}`,
      method: 'GET',
    }).then((res: any) => {
      if (parseInt(res.data?.code) === 200) {
        resolve({
          token: res.data.data,
          key: _key
        });
      } else {
        reject({});
      }
    }).catch(error => {
      reject({});
    })
  })
}


export const upload = async (file: any): Promise<any> => {
  const fileName = file.name;
  const index = fileName.lastIndexOf(".");
  const fileExtension = index >= 0 ? fileName.slice(index + 1) : ''; // 获取文件后缀
  const data = await getQiniuUploadToken(fileExtension);
  return new Promise((resolve, reject) => {
    const observable = qiniu.upload(file, data.key, data.token);
    const subscription = observable.subscribe({
      next(res: any) {},
      error(err: any) {reject(err)},
      complete: async (res: any) => {
        const result: any = await qiniu.imageInfo(res.key, qiniuBaseURL!);
        let qiniuResult = {
          "url": qiniuBaseURL + res.key,
          "key": res.key,
          "width": result.data.width.toString(),
          "height": result.data.height.toString(),
          "ratio": +result.data.width / +result.data.height,
          "fsize": file.size.toString(),
          "fname": file.name,
          "mimeType": res.mimeType,
          "ext": fileExtension,
        }
        resolve(qiniuResult)
      }
    })
  })

  
}
