import { UserType } from "../types";

const LOGINTOKEN: string = process.env.REACT_APP_LOGIN_TOKEN!;
const USERINFO: string = process.env.REACT_APP_USER_INFO!;

export const getToken = () => {
  return window.localStorage.getItem(LOGINTOKEN) || ''
}

export const getUser = (): UserType => {
  const userStr = window.localStorage.getItem(USERINFO);
  const user: UserType = userStr ? JSON.parse(userStr) : {};
  return user;
}
