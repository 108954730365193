import { Suspense, useEffect, useReducer } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import routers from './routers';
import Header from './components/Header';
import Footer from './components/Footer';
import { UserContext, userReducer } from './utils/reducer';
import { getUser } from './utils/permission';

function App() {
  const [state, dispatch] = useReducer(userReducer, {});

  useEffect(() => {
    const user = getUser();
    dispatch({type: 'updateUser', payload: user})
  }, [])

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      <ConfigProvider locale={locale} theme={{
        components: {
          Pagination: {
            itemActiveBg: '#2c3e50',
          },
        }
      }}>
        <div className='layout-container'>
          <Header></Header>
          <div className='layout-body'>
            <Suspense>
              <Routes>
                {
                  routers.map((item, index) => {
                    return (
                      <Route key={index} path={item.path} element={<item.component />}>
                        {
                          item.children && item.children.length && item.children.map((sub, i) => {
                            return <Route key={i} path={sub.path} element={<sub.component />} />
                          })
                        }
                      </Route>
                    )
                  })
                }
              </Routes>
            </Suspense>
          </div>
          <Footer></Footer>
        </div>
      </ConfigProvider>
    </UserContext.Provider>
  );
}

export default App;
