export default [
  {
    label: '精品游戏',
    key: 'GAME',
    url: '/game',
    type: 'GAME',
    children: [
      {
        label: 'Switch游戏',
        key: 'GAME-SWITCH',
        url: '/game/switch',
        type: 'SWITCH'
      },
      {
        label: 'PC游戏',
        key: 'GAME-PC',
        url: '/game/pc',
        type: 'PC'
      },
    ]
  },
  {
    label: '视频教学',
    key: 'COURSE',
    url: '/course',
    type: 'COURSE'
  }
]