import { Modal, Input, Button, Form, Space, type FormProps, message } from 'antd';
import { useContext, useState } from 'react';
import { http } from '../../utils/fetch';
import { UserContext } from '../../utils/reducer';
import './index.scss';

interface LoginProps {
  show: boolean,
  onCancel: () => void
}

enum LoginTypeEnum {
  Login = 'login',
  Register = 'register'
}

const LOGINTOKEN: string = process.env.REACT_APP_LOGIN_TOKEN!;
const USERINFO: string = process.env.REACT_APP_USER_INFO!;
let resetEmail: string;
let registerEmail: string;
let resetTimer: any;
let regesterTimer: any;
export default ({ show, onCancel }: LoginProps) => {
  const [loginType, setLoginType] = useState<LoginTypeEnum>(LoginTypeEnum.Login);
  const [loading, setLoading] = useState<boolean>(false);
  const [resetPassword, setResetPassword] = useState<boolean>(false);
  const { dispatch } = useContext(UserContext);
  const [codeTxt, setCodeTxt] = useState<string>('发送验证码');
  const [registerCodeTxt, setRegisterCodeTxt] = useState<string>('发送验证码');
  const [codeLoading, setCodeLoading] = useState<boolean>(false);

  const typeClickHandle = (type: LoginTypeEnum) => {
    if (type == loginType) return;
    setLoginType(type);
    setLoading(false);
  }

  const cancelHandle = () => {
    if (loading) return;
    setLoading(false);
    onCancel();
  }

  //登陆API
  const loginHandle: FormProps["onFinish"] = async (values) => {
    if (loading) return;
    setLoading(true);
    try {
      const data: any = await http('/register/login', {
        method: 'POST',
        data: {
          nickName: values.account,
          password: values.loginPassword
        }
      })
      message.success('登录成功');
      localStorage.setItem(LOGINTOKEN, data.token);
      localStorage.setItem(USERINFO, JSON.stringify(data.user));
      dispatch({type: 'updateUser', payload: {
        ...data.user,
        costScore: Math.abs(data.costScore)
      }})
      onCancel();
    } catch(e) {
    } finally {
      setLoading(false);
    }
  };
  //注册API
  const registerHandle: FormProps["onFinish"] = (values) => {
    if (loading) return;
    if (values.password != values.confirmPassword) {
      message.error('两次输入密码不一致！')
      return;
    }
    setLoading(true);
    http('/register/createUser', {
      method: 'POST',
      data: {
        nickName: values.username,
        email: values.email,
        password: values.password,
        invitedCode: values.invitedCode,
        code: values.registerCode
      }
    }).then(res => {
      message.success('注册成功!请登录')
      setLoginType(LoginTypeEnum.Login)
    }).catch(e => {
      console.log('e', e);
    }).finally(() => {
      setLoading(false);
    })
  };
  //重置密码API
  const resetPasswordHandle: FormProps["onFinish"] = (values) => {
    if (loading) return;
    setLoading(true);
    http('/register/retrievePassword', {
      method: 'POST',
      data: {
        email: values.resetEmail,
        password: values.resetPassword,
        code: values.code
      }
    }).then(res => {
      clearResetTimer();
      message.success('密码重置成功!请登录')
      setLoginType(LoginTypeEnum.Login);
      setResetPassword(false);
    }).catch(e => {
      //..
    }).finally(()=>{
      setLoading(false);
    })
  };
  //发送注册验证邮件
  const sendRegisterEmailCode = async () => {
    if (registerCodeTxt != '发送验证码' || codeLoading) {
      return;
    }
    if (!registerEmail) {
      message.error('请输入正确的邮箱地址');
      return;
    }
    setCodeLoading(true);
    try {
      await http(`/register/sendRegisterEmail?email=${registerEmail}`);
      setRegisterCountDown();
    } catch(e) {
      //...
    } finally {
      setCodeLoading(false);
    }
  }
  //发送重置密码验证邮件
  const sendEmailCode = async () => {
    if (codeTxt != '发送验证码' || codeLoading) {
      return;
    }
    if (!resetEmail) {
      message.error('请输入正确的邮箱地址');
      return;
    }
    setCodeLoading(true);
    try {
      await http(`/register/sendEmail?email=${resetEmail}`);
      setResetCountDown();
    } catch(e) {
      //...
    } finally {
      setCodeLoading(false);
    }
  }
  //重置密码倒计时
  const setResetCountDown = () => {
    let counter = 60;
    setCodeTxt(counter+'s');
    resetTimer = setInterval(() => {
      if (counter > 0) {
        counter--;
        setCodeTxt(counter+'s');
      } else {
        clearResetTimer();
        setCodeTxt('发送验证码');
      }
    }, 1000)
  }
  //清除重置密码倒计时
  const clearResetTimer = () => {
    clearInterval(resetTimer);
    resetTimer = null;
  }
  //注册倒计时
  const setRegisterCountDown = () => {
    let counter = 60;
    setRegisterCodeTxt(counter+'s');
    regesterTimer = setInterval(() => {
      if (counter > 0) {
        counter--;
        setRegisterCodeTxt(counter+'s');
      } else {
        clearRegisterTimer();
        setRegisterCodeTxt('发送验证码');
      }
    }, 1000)
  }
  //清除注册倒计时
  const clearRegisterTimer = () => {
    clearInterval(regesterTimer);
    regesterTimer = null;
  }

  return (
    <Modal
      className='login-modal'
      centered
      open={show}
      width={340}
      closable={false}
      footer={false}
      onCancel={cancelHandle}
    >
      <div className='login-container'>
        <div className='login-logo'>
          <img src={require('../../assets/logo.png')} alt="" />
        </div>
        {
          !resetPassword ? (
            <>
              <div className='controls'>
                <span className={loginType == LoginTypeEnum.Login ? 'active' : ''} onClick={() => typeClickHandle(LoginTypeEnum.Login)}>登录</span>
                <span className={loginType == LoginTypeEnum.Register ? 'active' : ''} onClick={() => typeClickHandle(LoginTypeEnum.Register)}>注册</span>
              </div>
              {
                loginType == LoginTypeEnum.Login ? (
                  <Form name='loginForm' className='login-sec' onFinish={loginHandle}>
                    <Form.Item name="account" className="item" rules={[{ required: true, message: '请输入正确的邮箱地址', type: 'email'}]}><Input placeholder="请输入正确的邮箱地址" /></Form.Item>
                    <Form.Item name="loginPassword" className="item" rules={[{ required: true, message: '请输入密码'}]}><Input.Password placeholder="请输入密码"  /></Form.Item>
                    <Button loading={loading} className='btn' htmlType="submit">登录</Button>
                  </Form>
                ) : (
                  <Form name="registerForm" className='register-sec' onFinish={registerHandle}>
                    <Form.Item name="email" className='item' rules={[{ required: true, message: '请输入邮箱地址', type: 'email'}]}><Input placeholder="请输入正确的邮箱地址" onChange={(e: any) => registerEmail = e.target.value} /></Form.Item>
                    <Form.Item name="registerCode" className='item' rules={[{ required: true, message: '请输入验证码'}]}>
                      <Space.Compact style={{ width: '100%' }}>
                        <Input placeholder="输入验证码"  />
                        <Button type="primary" onClick={sendRegisterEmailCode} loading={codeLoading}>{registerCodeTxt}</Button>
                      </Space.Compact>
                    </Form.Item>
                    <Form.Item name="username" className='item' rules={[{ required: true, message: '请输入用户昵称'}]}><Input placeholder="请输入用户昵称" /></Form.Item>
                    <Form.Item name="password" className="item" rules={[{ required: true, message: '请输入密码'}]}><Input.Password placeholder="密码最小长度为6"  /></Form.Item>
                    <Form.Item name="confirmPassword" className="item" rules={[{ required: true, message: '请再次输入密码'}]}><Input.Password placeholder="再次输入密码"  /></Form.Item>
                    <Form.Item name="invitedCode" className="item"><Input placeholder="输入邀请码"  /></Form.Item>
                    <Button loading={loading} className='btn' htmlType='submit'>立即注册</Button>
                  </Form>
                )
              }
              <p className='tips' onClick={() => setResetPassword(true)}>忘记密码</p>
            </>
          ) : (
            <>
              <div className='controls'>
                <span className='active'>通过邮箱找回</span>
              </div>
              <Form name="resetPasswordForm" className='reset-password-sec' onFinish={resetPasswordHandle}>
                <Form.Item name="resetEmail" className='item' rules={[{ required: true, message: '请输入正确的邮箱地址', type: 'email'}]}><Input placeholder="输入绑定邮箱" onChange={(e: any) => resetEmail = e.target.value} /></Form.Item>
                <Form.Item name="code" className='item' rules={[{ required: true, message: '请输入验证码'}]}>
                  <Space.Compact style={{ width: '100%' }}>
                    <Input placeholder="输入验证码"  />
                    <Button type="primary" onClick={sendEmailCode} loading={codeLoading}>{codeTxt}</Button>
                  </Space.Compact>
                </Form.Item>
                <Form.Item name="resetPassword" className="item" rules={[{ required: true, message: '请输入密码'}]}><Input.Password placeholder="密码最小长度为6"  /></Form.Item>
                <Button loading={loading} className='btn' htmlType='submit'>确认</Button>
              </Form>
              <p className='tips' onClick={() => setResetPassword(false)}>登录/注册</p>
            </>
          )
        }
      </div>
    </Modal>
  )
}